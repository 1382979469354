/* ~~~~~~~~~~~~~~~~~~~~~~ GENERAL CSS ~~~~~~~~~~~~~~~~~~~~~~ */

body {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    background-color: var(--greenysh);
    font-family: 'W95FA', Arial, serif;
    position: fixed;
    cursor: url('../images/loading.png');
}

@font-face {
    font-family: 'W95FA';
    src: url('../fonts/W95FA.otf') format('opentype');
    src: url('../fonts/w95fa.woff') format('woff');
    src: url('../fonts/w95fa.woff2') format('woff2');
}

:root {
    --white: #fff;
    --black: #000;
    --darkGrey: #7e7e7e;
    --lightGrey: #bebebe;
    --greenysh: #047e7e;
    --darkBlue: #00007e;
    --deathBlue: #0000ff;
}

#specialCursor {
    cursor: url('../images/loading.png');
}

/* ~~~~~~~~~~~~~~~~~~~~~~ BOTTOM BAR ~~~~~~~~~~~~~~~~~~~~~~ */

#bottomBar {
    height: 50px;
    width: 100%;
    background-color: var(--lightGrey);
    position: fixed;
    bottom: 0;
    border-top: 4px var(--white) solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#startButton,
#clock {
    display: flex;
    align-items: center;
    height: 40px;
    width: 105px;
    justify-content: space-around;
}

.navBarLogo {
    height: 35px;
    width: 35px;
    margin: 0 10px;
}

#rightSideBottomeBar{
    display: flex;
}

/* ~~~~~~~~~~~~~~~~~~~~~~ START BUTTON ~~~~~~~~~~~~~~~~~~~~~~ */

#startButton {
    background-color: var(--lightGrey);
    border-top: 4px var(--white) solid;
    border-left: 4px var(--white) solid;
    margin-left: 5px;
}

#startButton:hover {
    cursor: pointer;
}

/* ~~~~~~~~~~~~~~~~~~~~~~ TIMER  ~~~~~~~~~~~~~~~~~~~~~~ */

#clock {
    background-color: var(--lightGrey);
    border-top: 3px var(--darkGrey) solid;
    border-left: 3px var(--darkGrey) solid;
    border-bottom: 3px var(--white) solid;
    border-right: 3px var(--white) solid;
    margin-right: 5px;
}

#clock:active {
    border-style: outset;
}

#clock > img {
    height: 20px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~ DESKTOP ~~~~~~~~~~~~~~~~~~~~~~ */

#desktop {
    height: 92vh;
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

/* ~~~~~~~~~~~~~~~~~~~~~~ DESKTOP ICONS ~~~~~~~~~~~~~~~~~~~~~~ */

.iconsComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    height: 75px;
    width: 110px;
    cursor: pointer;
}

.iconsComponent > img {
    height: 50px;
    width: 50px;
}
.iconsComponent > p {
    margin-top: 4px;
    color: var(--white);
}

/* ~~~~~~~~~~~~~~~~~~~~~~ MODAL WINDOW ~~~~~~~~~~~~~~~~~~~~~~ */

#windowModal {
    height: 450px;
    width: 750px;
    position: absolute;
    left: 25%;
    top: 10%;
    z-index: 1;

    background-color: var(--lightGrey);
    border-top: 2px solid var(--white);
    border-left: 2px solid var(--white);
    border-bottom: 3px var(--black) solid;
    border-right: 3px var(--black) solid;
    padding: 3px;
}

#windowModal > img {
    height: 50px;
    width: 50px;
}

#topBarWindowModal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 0 3px 0 5px;
    background-color: var(--darkBlue);
    color: var(--white);
    cursor: pointer;
}

.search,
.close {
    height: 15px;
    width: 15px;
    cursor: pointer;
    border-top: 2px solid var(--white);
    border-left: 2px solid var(--white);
    margin-right: 2px;
    background-repeat: no-repeat;
    background-position: center;
}

.search {
    background-image: url('../images/question-icon.png');
}
.close {
    background-image: url('../images/close-icon.png');
}

#windowModal > div:nth-child(2) {
    display: flex;
    height: 20px;
}
#windowModal > div:nth-child(2) > p {
    cursor: pointer;
    margin: 5px 5px 3px;
}
#windowModal > div:nth-child(2) > p:hover {
    margin: 5px 5px 3px;
    background-color: var(--darkGrey);
}

.centerModal {
    height: 357px;
    border-top: 2px solid var(--darkGrey);
    border-left: 2px solid var(--darkGrey);
    background-color: var(--white);
}

#bottomModal {
    height: 30px;
    margin-top: 4px;
    display: flex;
}
#bottomModal > div {
    height: 30px;
    display: flex;
    align-items: center;
    border-top: 2px solid var(--darkGrey);
    border-left: 2px solid var(--darkGrey);
    border-bottom: 2px solid var(--white);
    border-right: 2px solid var(--white);
    padding-left: 5px;
}
#bottomModal > div:nth-child(1) {
    width: 60%;
}
#bottomModal > div:nth-child(2) {
    width: 40%;
}

.fileInFolder {
    display: flex;
    margin-top: 5px;
    color: var(--black);
}

#myPictureInner {
    background: url('../images/myPicture.png') no-repeat;
    height: 100%;
    width: 100%;
}

/* ~~~~~~~~~~~~~~~~~~~~~~ MODAL ERROR ~~~~~~~~~~~~~~~~ */

#windowModalError {
    height: 170px;
    width: 550px;
    position: absolute;
    left: 25%;
    top: 25%;
    z-index: 1;

    background-color: var(--lightGrey);
    border-top: 2px solid var(--white);
    border-left: 2px solid var(--white);
    border-bottom: 3px var(--black) solid;
    border-right: 3px var(--black) solid;
    padding: 3px;
}

.errorMessage {
    display: flex;
    justify-content: space-around;
    margin-top: 35px;
    color: var(--black);
}

.buttonOk {
    height: 30px;
    width: 125px;
    margin-top: 20px;
    margin-left: 40%;
    cursor: pointer;
    background-color: var(--lightGrey);
    border-top: 2px solid var(--white);
    border-left: 2px solid var(--white);
    border-bottom: 3px var(--black) solid;
    border-right: 3px var(--black) solid;
}

.errorImg {
    height: 45px;
    width: 45px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~ MODAL READ ME ~~~~~~~~~~~~~~~~~~~~~~~ */

#readMeModal {
    padding: 0 25px;

    font-size: 20px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~ WEB PAGE WINDOW ~~~~~~~~~~~~~~~~~~~~~~ */

#webPageModal {
    height: 100%;
    width: 140%;
    position: absolute;
    left: -30%;
    top: -10%;
    z-index: 1;

    background-color: var(--lightGrey);
    border-top: 2px solid var(--white);
    border-left: 2px solid var(--white);
    border-bottom: 3px var(--black) solid;
    border-right: 3px var(--black) solid;
    padding: 3px;
}

#webPageModal > div:nth-child(2) {
    display: flex;
    height: 20px;
}
#webPageModal > div:nth-child(2) > p {
    cursor: pointer;
    margin: 5px 5px 3px;
}
#webPageModal > div:nth-child(2) > p:hover {
    margin: 5px 5px 3px;
    background-color: var(--darkGrey);
}

#centerWebPageModal {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    height: 77.5%;
    border-top: 2px solid var(--darkGrey);
    border-left: 2px solid var(--darkGrey);
    background-color: var(--white);
    font-size: 18px;
}

#centerWebPageModal > h1 {
    text-align: center;
}

#centerWebPageModal > p {
    padding: 20px;
}

#centerWebPageModal > img {
    height: 500px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.logoProject {
    height: 100px !important;
}

#addressBar {
    display: flex;
    align-items: center;
}

#addressBar input {
    height: 20px;
    width: 100%;
    margin-left: 5px;
}

.spaceLink {
    margin-left: 5px;
    cursor: pointer;
}

/* ~~~~~~~~~~~~~~~~~~~~~~ MENU ~~~~~~~~~~~~~~~~~~~~~~ */

#Menu {
    position: absolute;
    height: 365px;
    width: 250px;
    background-color: var(--lightGrey);
    top: -373px;
    left: 3px;
    border-top: 4px solid var(--white);
    border-left: 4px solid var(--white);
    border-bottom: 4px solid var(--darkGrey);
    border-right: 4px solid var(--darkGrey);
}

#Menu h2,
h3 {
    margin: 0;
    width: 120px;
}

.line {
    border-top: 2px solid var(--darkGrey);
    border-bottom: 2px solid var(--white);
    margin: 8px 0 2px 0;
}

/* ~~~~~~~~~~~~~~~~~~~~~~ ICON MENU  ~~~~~~~~~~~~~~~~~~~~~~ */

.load {
    cursor: url('../images/loading.png');
}

#MenuIcon {
    display: flex;
    align-items: center;
    margin: 5px 0;
}

#MenuIcon:hover {
    cursor: pointer;
    background-color: var(--darkBlue);
    color: var(--white);
}

#MenuIcon > img {
    height: 45px;
    width: 45px;
    margin: 0 20px;
}

#MenuIcon > #arrowMenu {
    height: 10px;
    width: 10px;
}

/* ~~~~~~~~~~~~~~~~~~~~~~ SHUT DOWN  ~~~~~~~~~~~~~~~~~~~~~~ */

#shutDownScreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 1);
    cursor: none;
}

/* ~~~~~~~~~~~~~~~~~~~~~~ MINI MENU - RIGTH CLICK ~~~~~~~~~~~~~~~~~~~~~~ */

#miniIconMenu {
    margin: 2px 0;
    padding: 10px 0 10px 10px;
}

#miniIconMenu:hover {
    background-color: var(--darkBlue);
    color: var(--white);
}
